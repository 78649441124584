body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#map {
  height: 180px;
}

.leaflet-control-attribution a:first-of-type {
  display: none;
}

.wrapList {
  overflow: auto;
  height: 100vh;
  display: flex;
  position: absolute;
  right: 0px;
  z-index: 999;
  flex-direction: column;
}

.selectDistrict {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0;
  width: calc(100vw - 40px); /* Updated width */
  z-index: 99999999;
  /* background-color: white; */
  height: 50px;

  /* background-color: red; */
}
.leaflet-top {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
  -webkit-touch-callout: none; /* iOS Safari */
}
